


























































































import wAxios from '@/plugins/w/axios'

const api = {
  getGroup:     (id)        => wAxios.get_auth(`/v1/group-admin/groups/${id}`),
  createGroup:  (data)      => wAxios.post_auth(`/v1/group-admin/groups`, data),
  updateGroup:  (id, data)  => wAxios.put_auth(`/v1/group-admin/groups/${id}`, data),
  deleteGroup:  (id)        => wAxios.delete_auth(`/v1/group-admin/groups/${id}`)
}

export default {

	components: { 
    'a-header':                   () => import('@/plugins/app/_layout/a-header.vue'),
    'z-loader':                   () => import('@/plugins/app@components/loader/z-loader.vue'),
    'a-student-group-share':      () => import('../_components/student-group-share-modal/a-student-group-share.vue'),
    'a-student-group-duplicate':  () => import('../_components/student-group-duplicate-modal/a-student-group-duplicate.vue'),
    'a-group-student-row':        () => import('./a-group-student-row.vue'),
  },

  data() {
    return {
      loadingGroup: false,
      isSaveDisabled: false,
      group: {
        id: this.$route.params.id || null,
        name: '', 
        members: []
      },
      groupName: '',
      savedGroup: null,
      ignoreGroupChange: false,
      unsavedChanges: false,
      currentUser: this.$store.getters['wAuth/user']
    }
  },

  async mounted() {
    await this.loadGroup()
  },

  watch: {
    group: { 
      handler: function(newValue) {
        const savedGroupMembers = this.savedGroup?.members.filter(member => !!member.user.email)
        const newGroupMembers = newValue.members.filter(member => !this._isEmptyMember(member))

        if (
          this.savedGroup?.name != newValue.name ||
          savedGroupMembers?.length != newGroupMembers?.length ||
          savedGroupMembers?.some((member, i) => {
            return member.user.email != newGroupMembers[i].user.email || 
              member.user.name != newGroupMembers[i].name || 
              member.user.surname != newGroupMembers[i].surname    
          })
        ) {
          this.unsavedChanges = true
        } else {
          this.unsavedChanges = false
        }
      },
      deep: true
    } 
  },

  methods: {
    async loadGroup() {
      if (this.group.id) {
        this.loadingGroup = true
        try {
          ({ data: { data: this.group } } = await api.getGroup(this.group.id))
          this.savedGroup = JSON.parse(JSON.stringify(this.group))
        } catch(err) {
          this.$wToast.error(err)
        } finally {
          this.loadingGroup = false
        }
      }

      this.addStudent()
    },

    addStudent(i?: number) {
      this.group.members.splice(Number.isInteger(i) ? ((i || 0) + 1) : this.group.members?.length, 0, {
        user: { 
          email: ''
        },
        name: '',
        surname: '',
        status: 0
      })
    },

    async deleteStudent(i) {
      if (!this._isEmptyMember(this.group.members[i])) {
        const confirm = await this.$bvModal.msgBoxConfirm('Naozaj chcete vymazať tohto študenta?', {
          title: 'Vymazať študenta?',
          okTitle: 'Vymazať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false,
        })

        if (!confirm) {
          return
        }
      }

      this.group.members.splice(i, 1)
    },

    async duplicateGroup() {
      this.$bvModal.show('DuplicateGroupModal')
    },

    async shareGroup() {
      this.$bvModal.show('ShareGroupModal')
    },

    async deleteGroup() {
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Naozaj chcete vymazať túto skupinu?', {
          title: 'Vymazať skupinu?',
          okTitle: 'Vymazať',
          okVariant: 'a-primary',
          cancelTitle: 'Zrušiť',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false,
        })

        if (confirm) {
          await api.deleteGroup(this.group.id)
          this.$router.push({name: 'StudentGroups'})
        }
      } catch(err) {
        this.$wToast.error(err)
      }
    },
    
    async save() {
      // TODO: add validations
      let do_invitation = false
      if (this.group.members.some(member => member.user?.email && !member.is_invited)) {
        do_invitation = await this.$bvModal.msgBoxConfirm(
          'Chcete odoslať pozvánky pre nových študentov?', 
          {
            title: 'Odoslať pozvánky?',
            okTitle: 'Uložiť a odoslať',
            okVariant: 'a-primary',
            cancelTitle: 'Uložiť, ale neodoslať',
            cancelVariant: 'a-primary--outline',
            buttonSize: 'sm',
            hideHeaderClose: false,
          }
        )

        if (do_invitation == null) {
          return
        }
      }

      try {
        if (this.group.id) {
          await api.updateGroup(this.group.id, this._getGroupForApi(do_invitation))
        } else {
          await api.createGroup(this._getGroupForApi(do_invitation, true))
        }
        this.$router.push({name: 'StudentGroups'})
      } catch (err) {
        this.$wToast.error(err)
      }
    },

    async leave() {
      if (this.unsavedChanges) {
        const saveChanges = await this.$bvModal.msgBoxConfirm('Naozaj chcete odísť bez uloženia úprav?', {
          title: 'Neuložené zmeny!',
          okTitle: 'Uložiť zmeny',
          okVariant: 'a-primary',
          cancelTitle: 'Zahodiť zmeny',
          cancelVariant: 'a-primary--outline',
          buttonSize: 'sm',
          hideHeaderClose: false,
        })

        switch(saveChanges) {
          case true: return this.save()
          case false: return this.$router.push({name: 'StudentGroups'})
          default: return
        }
      }

      this.$router.push({name: 'StudentGroups'})
    },

    _getGroupForApi(do_invitation, create = false) {
      const groupData = {
        do_invitation, 
        name: this.group.name,
        members: this.group.members
          .filter(member => !!member.user.email)
          .map(member => ({
            id: member.id || null,
            name: member.name, 
            surname: member.surname, 
            email: member.user.email 
          }))
      }

      if (create) {
        groupData['admin'] = {
          name: this.currentUser.name,
          surname: this.currentUser.surname || ''
        }
      }

      return groupData
    },

    _isEmptyMember(member) {
      return !(member.user.email || member.name || member.surname)
    }
  }

}
